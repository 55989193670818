<template>
  <div class="header" :style="bgColor ? 'background-color: ' + bgColor  :''" :class=" isfixed ? 'fixed' : isrelative ? 'relative' : ''">
    <div class="siteNav">
      <div class="container flex flex-between align-center">
        <div class="qualification">
          <img src="@/assets/images/qualification.png" alt="" />
          <span style="margin-left: 15px">咨询电话：17801382180</span>
        </div>
        <div class="topNav">
          <ul>
            <li>
              <router-link to="/">首页</router-link>
            </li>
            <li>
              <router-link to="/myOrder">我的订单</router-link>
            </li>
<!--            <li>
              <router-link to="/keying/member">科英会员</router-link>
            </li>
            <el-divider direction="vertical"></el-divider>-->
            <li>
              <router-link to="/keying/cooperate">合作入驻</router-link>
            </li>
            <li>
              <router-link to="/keying/news">科英资讯</router-link>
            </li>
            <li>
              <router-link to="/keying/recruit">科英招聘</router-link>
            </li>
            <li>
              <router-link to="/keying/about">关于我们</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="headerBar">
      <div class="container">
        <div class="top flex flex-between align-center">
          <div class="brand">
            <router-link to="/" class="logo">
              <img src="@/assets/images/logo.svg" alt="" />
            </router-link>
          </div>
          <div class="search">
            <el-input :placeholder="searchPlaceholder" v-model="keywords" class="search-ipt" prefix-icon="el-icon-search" @focus="showSearchBtn=true" @keyup.enter.native="goSearch(keywords)">
              <div slot="append" class="keyword-tag" v-if="!showSearchBtn">
                <div class="keyword-tag-item" v-for="item in keywords_arr" @click="goSearch(item)">{{item}}</div>
              </div>
            </el-input>
            <el-button class="btn-search" @click="goSearch(keywords)">搜索</el-button>
            <el-button class="btn-post" @click="$router.push('/demand')">发布需求</el-button>
          </div>
          <div class="right">
            <el-button class="btn-recharge" icon="ri-gift-line" @click="$router.push('/recharge')">预存<div v-if="ratio" class="recharge-tips">赠送{{ratio}}%</div></el-button>
            <el-button class="btn-login" icon="ri-user-2-line" v-if="!hasLogin" @click="$router.push('/login')">注册/登录</el-button>
            <el-button class="btn-login" icon="ri-user-2-line" v-if="hasLogin" @click="$router.push('/userCenter')">个人中心</el-button>
          </div>
        </div>
      </div>
      <div class="nav" :class="sticky?'sticky':''">
        <div class="container">
          <div class="flex flex-between align-center" @mouseenter="showCate = true" @mouseleave="leaveCate">
            <div @mouseenter="enterCate('info_retrieval')" >
              <router-link  class="nav-item" to="/retrieval">信息检索</router-link>
            </div>
            <div @mouseenter="enterCate('count_simulation')">
              <router-link   class="nav-item" to="/simulate">计算模拟</router-link>
            </div>
            <div  @mouseenter="enterCate('analysis_test')">
              <router-link class="nav-item" to="/analysis">分析测试</router-link>
            </div>
            <div  @mouseenter="enterCate('research_mall')">
              <router-link class="nav-item" to="/shop">科英商城</router-link>
            </div>
            <div  @mouseenter="enterCate('thesis_patent')">
              <router-link class="nav-item" to="/thesis" style="pointer-events: none;">论文服务</router-link>
            </div>
            <div  @mouseenter="enterCate('title_application')">
              <router-link class="nav-item" to="/title">专利服务</router-link>
            </div>
            <div>
              <router-link class="nav-item" to="/patent/market">专利转让</router-link>
            </div>
<!--            <div  @mouseenter="enterCate('outcome_evaluation')">-->
<!--              <router-link class="nav-item" to="/outcome">成果评价</router-link>-->
<!--            </div>-->

            <div>
              <router-link class="nav-item" to="/funding">科研基金</router-link>
            </div>
						<div>
							<router-link class="nav-item" to="/projects">企业项目</router-link>
						</div>
<!--            <div  @mouseenter="enterCate('meeting_services')">-->
<!--              <router-link class="nav-item" to="/meeting">会议服务</router-link>-->
<!--            </div>-->
            <div  @mouseenter="enterCate('scholar_recruit')">
              <router-link class="nav-item" to="/position">硕博招聘</router-link>
            </div>
            <div  @mouseenter="enterCate('experts_library')">
              <router-link class="nav-item" to="/expert">专家学者库</router-link>
            </div>
<!--            <div  >-->
<!--              <router-link class="nav-item" to="/news">科研资讯</router-link>-->
<!--            </div>-->
            <div  @mouseenter="enterCate('coing_college')">
              <router-link class="nav-item" to="/video">科英学院</router-link>
            </div>
          </div>
        </div>
        <div class="nav-hover" @mouseleave="leaveCateSub" @mouseenter="showCate = true" v-show="showCate && category.length">
          <div class="container">
            <div class="nav-hover-box">
              <div class="nav-hover-item" v-for="(item,index) in category" :key="item.dictValue">
                <div class="big-title">
                  <router-link class="big-title-link" :to="toCategory(item,null)" >
                    <img v-if="item.icon" :src="item.icon" alt="" />
                    <span>{{item.label}}</span>
                  </router-link>
                </div>
                <div class="nav-hover-sub" v-if="item.children && item.children.length && idx <= 3" v-for="(itm,idx) in item.children" :key="itm.value">
                  <router-link class="sub-title-link" :to="toCategory(itm,item)" >
                    <span>{{itm.label}}</span>
                  </router-link>
                </div>
                <div class="nav-hover-sub" v-if="item.children && item.children.length > 4" >
                  <router-link class="sub-title-link" :to="toCategory(item,null)" >
                    <span>更多</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'
import {getMenuList} from "@/api/basic";
import {getItemWithExpiry} from "@/utils/localsession"
import {apiRechargeConfigList} from "@/api/api";

const {mapMutations, mapGetters} = createNamespacedHelpers('search')

export default {
  name: "headerBar",
  props: {
    isfixed:{
      type: Boolean,
      default: false
    },
    isrelative:{
      type: Boolean,
      default: false
    },
    bgColor:{
      type: String,
      default: '#fff'
    }
  },
  data() {
    return {
      sticky: false,
			ratio: '',
    	key: '',
      i: 0,
      searchPlaceholder: '',
      keywords_arr: ['FIB','TEM','SEM'],
      hasLogin: false,
      category: [],
      categorys: [],
      showCate: false,
      showSearchBtn:false,
      keywords: this.$route.query.keywords || '',
    }
  },
  mounted(){
    window.addEventListener('scroll', () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(scrollTop > 140){
        this.sticky = true;
      }else{
        this.sticky = false;
      }
    })
  },
  created() {
    if(getItemWithExpiry('coing_token')){
      this.hasLogin = true;
    }else{
      this.hasLogin = false;
    }
    this.getMenuList();
    this.getKeywordsArr();
    this.getRechargeConfigList();

  },
  methods: {
		getRechargeConfigList(){
			apiRechargeConfigList().then(res=>{
				let ratio = 0;
				res.data.forEach(item=>{
					if(item.ratio > ratio){
						ratio = item.ratio;
					}
				})
				this.ratio = ratio
			}).catch(err=>{
			})
		},
    getKeywordsArr(){
      setInterval(()=>{
        this.i++;
        if(this.i == 100){
          this.i = 0;
        }
        this.searchPlaceholder = this.keywords_arr[(this.i % 3)];
      },3000)
    },
    toCategory(item,parentItem = {}){
      if(item.key == 'url'){
        return item.url;
      }
      let cid = item.value;
      let key = item.key;
      let arr = {
          'info_retrieval': '/retrieval/list',
          'count_simulation': '/simulate',
          'analysis_test': '/analysis',
          'research_mall': '/shop',
          'thesis_patent': '/thesis/list',
          'outcome_evaluation': '/outcome/list',
          'title_application': '/title',
          'funding_projects': '/funding/list',
          'meeting_services': '/meeting/list',
          'scholar_recruit': '/position',
          'experts_library': '/retrieval/list',
          'coing_college': '/video',
      };

      if(key == 'thesis_patent' && (cid == 89 || cid == 5)){
      	return "/paperPolishing"
			}
      if(key == 'thesis_patent' && (cid == 92 || cid == 5)){
        return "/paperSubmission"
      }
      if(key == 'thesis_patent' && (cid == 93 || cid == 5)){
        return "/academicTranslation"
      }

      if(parentItem){
        return arr[key] + "?cid=" + cid  + "&pid=" + parentItem.value
      }else{
        return arr[key] + "?cid=" + cid  + "&pid=" + cid
      }
    },
    getMenuList(){
      getMenuList().then(res=>{
        if(res.code == 200 && res.data.length > 0){
          this.categorys = res.data;
        }else{
          this.categorys = [];
        }
      }).catch(err=>{
          this.categorys = [];
      })
    },
    enterCate(key) {
    	this.key = key;
      if(key == "funding_projects"){
        this.category = [
          {
            key: 'url',
            label: '经费',
            value: 0,
            url: '/funding'
          },
          {
            key: 'url',
            label: '项目',
            value: 0,
            url: '/projects'
          }
        ]
        return;
      }
			if(key == "meeting_services"){
				this.category = []
				return;
			}
      this.categorys.forEach(item=>{
        if(item.dictValue == key){
          this.category = item.trees;
        }
      })
    },

    leaveCate() {

      this.$route.path != "/" && (this.showCate = false);
      this.showCate = false;

    },
    leaveCateSub() {
			this.key = ''
      this.$route.path != "/" && (this.showCate = false);
      this.showCate = false;
      this.category = [];
    },
    //点击搜索按钮跳转到商品列表
    goSearch(val) {
      // console.log(val)
			// console.log("name",this.$route)
			this.$router.push({
				name: 'search',
				query: {
					model: this.$route.name,
          keywords: val
				}
			})
			return;

      if (this.$route.name !== 'category') {
        this.$router.push({
          name: 'category'
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header{
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0 4px 16px rgba(55,99,170,.1);
  &.fixed{
    position: fixed;
  }
  &.relative{
    position: relative;
  }
}
.siteNav{
  font-size: 12px;
  background-color: #2E3130;
  color: #fff;
  .container{
    height: 40px;
  }
  .qualification{
    display: flex;
    align-items: center;
  }
  .topNav{
    ul{
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      li{
        list-style: none;
        margin-right: 8px;
        display: flex;
        align-items: center;
        &:after{
          content: '';
          width: 1px;
          height: 10px;
          background: rgba(210,210,210,0.5);;
          margin-left: 8px;
        }
        &:last-child{
          margin-right: 0;
          &:after{
            display: none;
          }
        }
        a{
          display: block;
          font-size: 12px;
          color: #fff;
          text-decoration: none;
          &:hover{
            color: #fff;
          }
        }
      }
    }
  }
}
.headerBar{
  position: relative;
  .top{
    height: 100px;
  }
  .brand{
    display:flex;
    align-items: center;
  }
  .search{
    width: 500px;
    display: flex;
  }
  .honor{
    margin-left: 15px;
  }
  .nav{
    display: flex;
    justify-content: space-between;
    &.sticky{
      position: fixed;
      z-index: 9999;
      background: #fff;
      box-shadow: 0 4px 16px rgba(55, 99, 170, 0.1);
      top: 0;
      left: 0;
      right: 0;
    }
    .nav-item{
      color: #333333;
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      height: 45px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid transparent;
      box-sizing: border-box;
      &.router-link-active{
        color: #3554D1;
        border-bottom: 3px solid #3554D1;
      }
      &:hover{
        color: #3554D1;
        border-bottom: 3px solid #3554D1;
      }
    }
  }
  .nav-hover{
    display: block;
    position: absolute;
    z-index: 998;
    left: 0;
    right: 0;
    top: 100%;
    background: #fff;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
    padding: 30px 0 15px 0;
    border-radius: 0 0 10px 10px;
    border-top: 1px solid #f5f5f5;
    .nav-hover-box{
      display: flex;
      flex-wrap: wrap;
      .nav-hover-item{
        width: 16.666%;
        margin-bottom: 15px;
        .big-title{
          .big-title-link{
            display: flex;
            align-items: center;
            color: #333;
            font-size: 15px;
            margin-bottom: 5px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            img{
              width: 20px;
              height: 20px;
              display: block;
            }
            span{
              margin-left: 5px;
            }
            &:hover{
              color: #3554D1;
              font-weight: 600;
            }
          }
        }
        .sub-title-link{
          display: block;
          padding-left: 25px;
          font-size: 14px;
          color: #697488;
          margin-bottom: 5px;
          &:hover{
            color: #3554D1;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.btn-recharge{
  height: 45px;
  background-image: linear-gradient(270deg, #FF6A00 0%, #FF8B1F 100%);
  color: #fff !important;
  border: none;
  cursor: pointer;
  position: relative;
  margin-right: 5px;
  &:hover{
    background-image: linear-gradient(270deg, #FF8B1F 0%, #FF6A00 100%);
  }
  .recharge-tips{
    position: absolute;
    z-index: 1;
    width: 55px;
    height: 16px;
    top: -10px;
    right: -10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(270deg, #FFCE76 0%, #FFE2AE 77%, #FFCE74 100%);
    box-shadow: 0 4px 8px 0 rgba(157,52,6,0.28);
    color: #B14300;
    &:before{
      position: absolute;
      left: 0;
      top: 100%;
      content:'';
      width: 0;
      height: 0;
      border-top: 2px solid #FFCE76;
      border-right: 2px solid transparent;
      border-bottom: 2px solid transparent;
      border-left: 2px solid #FFCE76;
    }
  }
}
.btn-post{
  height: 45px;
  background: #fff;
  color: #1677FF;
  border: 1px solid #1677FF;
  cursor: pointer;
}
.btn-login{
  height: 45px;
  background: #fff;
  color: #333;
  border: 1px solid #bdbdbd;
  cursor: pointer;
  &:hover{
    color: #fff;
    background-color: #3554D1;
    border: 1px solid #3554D1;
  }
}

//搜索框
.btn-search{
  background-image: linear-gradient(270deg, #4E6EF0 0%, #03A8FE 100%);
  border: none;
  color: #fff !important;
  height: 47px;
  width: 105px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  &:hover{
    background-image: linear-gradient(270deg, #03A8FE 0%, #4E6EF0 100%);
  }
}
.btn-post{
  background-image: linear-gradient(270deg, #36cfc9 0%, #08979c 100%);
  border: none;
  color: #fff !important;
  height: 47px;
  width: 105px;
  &:hover{
    background-image: linear-gradient(270deg, #08979c 0%, #36cfc9 100%);
  }
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.search-ipt{
  border-left: 1px solid #4E6EF0;
  border-top:1px solid #4E6EF0;
  border-bottom:1px solid #4E6EF0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-sizing: border-box;
  ::v-deep .el-input__inner {
    height: 45px;
    border: none;
    padding-left: 45px;
    font-size: 16px;
  }
  ::v-deep .el-input__icon{
    font-size: 20px ;
    line-height: 45px;
    width: 45px;
  }
  ::v-deep .el-input-group__append{
    border: none;
    background: #fff;
    padding: 0;
  }
  .keyword-tag{
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-right: 10px;
    .keyword-tag-item{
      padding: 4px 8px;
      background: #f5f5f5;
      margin-right: 5px;
      cursor: pointer;
      &:hover{
        background: #3554D1;
        color: #fff;
      }
    }
  }
}


</style>
